export const MenuItems = {
  "TACOS.svg": [
    {
      name: "Carnitas",
      price: 3.5,
      description: "Confit pork shoulder",
      image: "Taco-Carnitas.jpg",
    },
    {
      name: "Pollo Asada",
      price: 3.5,
      description: "Grilled chicken",
      image: "Taco-Grilled-Chicken.jpg",
    },
    {
      name: "Birria",
      price: 3.5,
      description: "Braised beef in mild chiles",
      image: "Taco-Birria.jpg",
    },
    {
      name: "Papas",
      price: 3.5,
      description: "Potato, soy chorizo (v)",
      image: "Taco-Papas.jpg",
    },
    {
      name: "Sweet Potato",
      price: 3.5,
      description: "Sweet potato, cheese",
      image: "Taco-Sweet-Potato.jpg",
    },
  ],
  "BURRITO.svg": [
    {
      name: "Birria",
      price: 12,
      description: "Beef braised in mild chiles",
      image: "Bowl-Birria.jpg",
    },
    {
      name: "Carnitas",
      price: 12,
      description: "Confit pork shoulder",
      image: "Bowl-Carnitas.jpg",
    },
    {
      name: "Pollo Asada",
      price: 12,
      description: "Grilled chicken",
      image: "Bowl-Grilled-Chicken.jpg",
    },
    {
      name: "Sweet Potato",
      price: 12,
      description: "",
      image: "Bowl-Sweet-Potato.jpg",
    },
    {
      name: "Papas",
      price: 12,
      description: "Potato, soy based chorizo",
      image: "Bowl-Papas.jpg",
    },
    {
      name: "+ Guacamole",
      price: 2,
      image: "Guacamole.jpg",
    },
  ],
  "PLATES.svg": [
    {
      name: "Quesabirria",
      price: 15,
      description: "3 cheesy braised beef fried tacos and consomme",
      image: "Quesabirria.jpg",
    },
    {
      name: "Pozole",
      price: 9,
      description: "Pork, cominy, avocado, red broth",
      image: "Pozole.jpg",
    },
    {
      name: "Kale Salad",
      price: 9,
      description: "Kale, cilantro lime vinaigrette, avocado, cheese",
      image: "Kale-Salad.jpg",
    },
  ],
  "STUFF.svg": [
    {
      name: "Pool Nachos",
      price: 12,
      description:
        "Chips slathered in nacho cheese, pickled jalapeno, salsa fresca",
      image: "Pool-Nachos.jpg",
    },
    {
      name: "+ Protein",
      price: 3,
      description: "Grilled chicken, confit pork, or braised beef",
      image: "Pool-Nachos.jpg",
    },
    {
      name: "Tortilla Chips",
      price: 5,
      description: "Sea salt, served with salsa roja",
      image: "Chips.jpg",
    },
    {
      name: "+ Guacamole",
      price: 4,
      description: "Avocado, onion, cilantro, jalapeño, lime",
      image: "Guacamole.jpg",
    },
    {
      name: "+  Salsa Macha",
      price: 2,
      description: "Dried chile, nuts, seeds, garlic, vinegar, salt, oil",
      image: "Salsa-Macha.jpg",
    },
    {
      name: "Extra Salsa",
      price: 1,
      description: "Spicy, medium, or mild",
      image: "Salsas.jpg",
    },
    {
      name: "Dessert Offering",
      description: "Ask Dylan or Cam for info",
      image: "Desert.jpg",
    },
  ],
};
