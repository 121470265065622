<template>
  <nav>
    <div class="nav-container">
      <ul class="nav-list caps nav-row-one">
        <li class="nav-item">
          <a
            class="nav-link"
            onclick="document.getElementById('food-anchor').scrollIntoView();"
            >FOOD</a
          >
        </li>
        <!-- <li
          class="nav-item"
          v-bind:class="products.length === 0 ? 'product-false' : ''"
        >
          <a
            class="nav-link"
            onclick="document.getElementById('product-anchor').scrollIntoView();"
            >PRODUCT</a
          >
        </li> -->
      </ul>
      <ul class="nav-list nav-bottom-rows caps">
        <li class="nav-item">
          <a class="nav-link" href="https://www.shelterbrewing.ca/reservations">
            RESERVATIONS
            <img
              class="icon right"
              src="../assets/images/right.svg"
              alt="&#x2192;"
            />
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { Products } from "@/components/Menu/Products";

export default {
  name: "Nav",
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
  data: () => {
    return {
      products: Products,
    };
  },
};
</script>

<style scoped>
.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
  justify-content: center;
  font-size: 18px;
}

.nav-item {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.nav-item:hover {
  border-bottom: 3px solid black;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.icon {
  height: 22px;
  margin-left: 4px;
}

.product-false {
  display: none;
}

@media only screen and (max-width: 880px) {
  .nav-container {
    flex-direction: column;
  }

  .nav-list {
    flex-direction: column;
  }

  .nav-bottom-rows .nav-item {
    margin: auto;
  }

  .nav-item {
    text-align: center;
  }

  .nav-row-one {
    flex-direction: row;
  }
}
</style>
