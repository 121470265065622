<template>
  <div class="panel-container">
    <div class="image-container">
      <img :src="src" class="preview-image desktop-image" />
      <img
        src="../assets/images/greeting.gif"
        alt="Dylan And Cams"
        class="preview-image mobile-image"
      />
    </div>
    <img class="logo" src="../assets/images/dc.svg" @mouseover="resetImage" />
    <div class="info">
      <span class="caps">LOCATED INSIDE </span>
      <span class="lower"
        >Shelter Brewing Co. @ 259 2 Ave S, Saskatoon, SK S7K 1K8, Canada
      </span>
      <br />
      <span class="caps">HOURS </span>
      <span class="lower">Tuesday to Saturday: 3–10pm, Sunday: 2–8pm </span>
      <br />
      <span class="caps">WE CATER </span>
      <span class="lower">For inquiries, please contact us </span>
      <br />
      <span class="caps">E-MAIL </span>
      <span class="lower">dylanandcams@gmail.com </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPanel",
  props: ["src"],
  methods: {
    resetImage: function () {
      this.$emit("update-image", "greeting.gif");
    },
  },
};
</script>

<style scoped>
.panel-container {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 10px;
  display: block;
}

.image-container {
  width: 100%;
}

.preview-image {
  width: 100%;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.logo {
  width: 120px;
  display: block;
  margin-top: 15px;
}

.info {
  text-align: left;
  width: 100%;
  margin-top: 15px;
}

.info span {
  line-height: 19px;
}

/*.info > .lower {*/
/*  margin-top: 4px;*/
/*}*/

.mobile-image {
  display: none;
}

@media only screen and (max-width: 600px) {
  .panel-container {
    padding-right: 20px;
  }

  .logo {
    width: 50%;
  }

  .mobile-image {
    display: inline-block;
  }

  .desktop-image {
    display: none;
  }
}
</style>
